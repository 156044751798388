import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  TooltipProps,
  TextField,
  Button,
  IconButton,
  Box,
  Select,
  SelectChangeEvent,
  MenuItem,
  styled,
  tooltipClasses,
} from '@mui/material'
import {
  Abc as TextIcon,
  ViewHeadline as TableIcon,
  AddBox as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridRenderCellParams,
  GridEditInputCell,
  GridRenderEditCellParams,
  GridPreProcessEditCellProps,
  GridCellParams,
  GridSelectionModel,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid'
import {
  WeightSensorNode,
  WeightSensorSegment,
} from 'models'
import {
  ConfirmDialog,
} from 'components'
import { sx } from './sx'

interface SegmentRowModel {
  id: number
  type: string
  segment: string
  status: 'Enabled' | 'Disabled' | undefined
}
const segmentToType = (segment: WeightSensorSegment | null) => segment?.split('_').filter(s => s !== '')[0] || 'NULL';
const segmentToSegmentCode = (segment: WeightSensorSegment | null) => segment?.split('_').filter(s => s !== '').splice(1).join('_') || '';
const segmentToStatus = (segment: WeightSensorSegment | null) => {
  if (segment === null) return undefined;
  return segment.startsWith('_') ? 'Disabled' : 'Enabled';
}
const toText = (segments: WeightSensorSegment[]): string => segments.join('\n');
const rowToSegment = (row: SegmentRowModel): WeightSensorSegment => {
  if (row.type === 'NULL') return null;
  if (row.status === 'Disabled') return `_${row.type}_${row.segment}`;
  return `${row.type}_${row.segment}`;
}
const textToSegments = (text: string): WeightSensorSegment[] | undefined => {
  const lines = text.split('\n')
  const result: WeightSensorSegment[] = []
  for (let i = 0, len = lines.length; i < len; i++) {
    const line = lines[i]
    if (line === '') {
      result.push(null)
    } else {
      if (!line.match(/^_?(CB01|CH01|CH02|OB01|OH01|ZB01)_[A-Z0-9]+_[0-9]+$/)) return undefined;
      result.push(line)
    }
  }
  return result;
}
const toGridRowsProp = (segments: WeightSensorSegment[]): GridRowsProp<SegmentRowModel> => {
  return segments.map((segment, idx) => ({
    id: (idx + 1),
    type: segmentToType(segment),
    segment: segmentToSegmentCode(segment),
    status: segmentToStatus(segment),
  }));
}

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MuiDataGrid-cell--editable': {
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

interface WeightSensorNodeDialogProps {
  open: boolean
  nodeId: string
  node: WeightSensorNode
  onUpdated: (node: WeightSensorNode) => void
  onDeleted: (id: string) => void
  onClose: () => void
}
export const WeightSensorNodeDialog: React.FC<WeightSensorNodeDialogProps> = ({
  open,
  nodeId,
  node,
  onUpdated,
  onDeleted,
  onClose,
}) => {
  const [segments, setSegments] = useState<WeightSensorSegment[]>([...node.segments])
  const [uneditedSegments, setUneditedSegments] = useState<WeightSensorSegment[]>([...node.segments])
  const [format, setFormat] = useState<'table' | 'text'>('table')
  const [rows, setRows] = useState<GridRowsProp<SegmentRowModel>>(toGridRowsProp(node.segments))
  const [segmentsText, setSegmentsText] = useState<string>(toText(node.segments))
  const [segmentsTextValidated, setSegmentsTextValidated] = useState<boolean>(true)
  const [openConfirmTextError, setOpenConfirmTextError] = useState<boolean>(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)

  useEffect(() => {
    setUneditedSegments([...node.segments])
    setSegments([...node.segments])
    setRows(toGridRowsProp(node.segments))
    setSegmentsText(toText(node.segments))
  }, [node])

  const handleFormatChanged = useCallback((_evt: React.MouseEvent<HTMLElement>, newFormat: 'table' | 'text') => {
    if (newFormat === 'table' && !segmentsTextValidated) {
      setOpenConfirmTextError(true);
      return;
    }
    switch (newFormat) {
      case 'text':
        setSegmentsText(toText(segments))
        setSegmentsTextValidated(true)
        break;
      case 'table':
        setRows(toGridRowsProp(segments))
        break;
      default:
        throw new Error();
    }
    setFormat(newFormat);
  }, [segments, segmentsTextValidated])

  // Grid
  const handleSegmentAdded = useCallback(() => {
    const newSegments = [...segments, null];
    setSegments(newSegments);
    setRows(toGridRowsProp(newSegments))
  }, [segments])
  const handleSegmentRemoved = useCallback((removeIds: number[]) => {
    if (removeIds.length === 0) return;
    const newSegments = segments.filter((_, idx) => removeIds.indexOf(idx + 1) === -1);
    setSegments(newSegments);
    setRows(toGridRowsProp(newSegments))
  }, [segments])

  const handleRowUpdated = useCallback((newRow: SegmentRowModel, previous: SegmentRowModel) => {
    if (newRow.type === 'NULL') {
      newRow.segment = '';
      newRow.status = undefined;
    } else if (previous.type === 'NULL') { // newRow.type !== 'NULL'
      newRow.segment = 'A_1'; // TODO 初期値
      newRow.status = 'Enabled';
    }
    const newSegments = [...segments];
    newSegments[newRow.id - 1] = rowToSegment(newRow);
    setSegments(newSegments);
    return newRow;
  }, [segments])

  const handleTextChanged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const text = evt.target.value
    setSegmentsText(text)
    const newSegments = textToSegments(text)
    if (newSegments !== undefined) {
      setSegments(newSegments);
      setRows(toGridRowsProp(newSegments))
      setSegmentsTextValidated(true)
    } else {
      setSegmentsTextValidated(false)
    }
  }, [])

  const handleConfirmTextErrorClosed = useCallback((isOk: boolean) => {
    setOpenConfirmTextError(false);
    if (!isOk) return;
    setFormat('table');
  }, [])

  const handleUpdated = useCallback(() => {
    onUpdated({
      node_id: nodeId,
      segments,
    })
  }, [nodeId, segments])
  const handleDeleted = useCallback(() => {
    onDeleted(nodeId)
  }, [nodeId, onDeleted])
  const handleConfirmDeleteClosed = useCallback((isOk: boolean) => {
    setOpenConfirmDelete(false);
    if (!isOk) return;
    handleDeleted()
  }, [])
  const handleCanceled = useCallback(() => {
    setSegments(uneditedSegments)
    setRows(toGridRowsProp(uneditedSegments))
    setSegmentsText(toText(uneditedSegments))
    onClose()
  }, [onClose, uneditedSegments])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <DialogTitle>Node ID: {nodeId}</DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'right', marginBottom: 0.5 }}>
          <ToggleButtonGroup
            size="small"
            exclusive
            value={format}
            onChange={handleFormatChanged}
          >
            <ToggleButton value="table">
              <Tooltip title="テーブル">
                <TableIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="text">
              <Tooltip title="テキスト">
                <TextIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <StyledBox sx={{ height: 700 }}>
          {format === 'table' && (
            <DataGrid
              rowHeight={26}
              headerHeight={32}
              autoPageSize
              columns={[
                {
                  headerName: 'Idx.',
                  field: 'id',
                  width: 20,
                },
                {
                  headerName: 'Type',
                  field: 'type',
                  width: 100,
                  editable: true,
                  renderCell: renderType,
                  renderEditCell: renderEditTypeCell,
                },
                {
                  headerName: 'Segment Code',
                  field: 'segment',
                  width: 150,
                  editable: true,
                  renderEditCell: renderEditSegment,
                  preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                    const value = params.props.value!.toString()
                    const error = !value.match(/^[A-Z0-9]+_[0-9]+$/) ? '不正フォーマット' : undefined;
                    return { ...params.props, error }
                  },
                },
                {
                  headerName: 'Status',
                  field: 'status',
                  editable: true,
                  renderCell: renderStatus,
                  renderEditCell: renderEditStatusCell,
                },
              ]}
              rows={rows}
              experimentalFeatures={{ newEditingApi: true }}
              isCellEditable={(params: GridCellParams) => {
                return (params.field === 'type' || params.row.type !== 'NULL')
              }}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              processRowUpdate={handleRowUpdated}
              components={{
                Header: GridHeader,
              }}
              componentsProps={{
                header: {
                  handleSegmentAdded,
                  handleSegmentRemoved,
                },
              }}
            />
          )}
          {format === 'text' && (
            <TextField
              style={{ width: '100%' }}
              placeholder={'CB01_A_1\nCB01_A_2\nCB01_A_3\nCB01_A_4\n\n\nCB01_A_5\nCB01_A_6\nCB01_A_7\nCB01_A_8'}
              multiline
              rows={30}
              value={segmentsText}
              onChange={handleTextChanged}
              error={!segmentsTextValidated}
              helperText={!segmentsTextValidated && '正しいフォーマットで入力してください'}
            />
          )}
        </StyledBox>
        <ConfirmDialog
          contentText="構文エラーがあります。内容を破棄して、よろしいですか？"
          open={openConfirmTextError}
          handleClose={handleConfirmTextErrorClosed}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          sx={[sx.action, { marginRight: 4 }]}
          onClick={() => setOpenConfirmDelete(true)}
        >
          削除
        </Button>
        <ConfirmDialog
          title="削除"
          open={openConfirmDelete}
          handleClose={handleConfirmDeleteClosed}
        />
        <Button
          color="inherit"
          variant="contained"
          sx={sx.action}
          onClick={handleCanceled}
        >
          キャンセル
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={sx.action}
          onClick={handleUpdated}
          disabled={!segmentsTextValidated}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )

}

// GridAPIをGridの外側から取得するためには、Proプランが必要
// このため、ヘッダー内でイベントをハンドリングする
// https://mui.com/x/react-data-grid/api-object/#use-it-outside-the-grid
interface GridHeaderProps {
  handleSegmentAdded: () => void
  handleSegmentRemoved: (removeIds: number[]) => void
}
const GridHeader: React.FC<GridHeaderProps> = ({ handleSegmentAdded, handleSegmentRemoved }) => {
  const apiRef = useGridApiContext()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const handleCellClicked = useCallback((params: any) => {
    switch (params.field) {
      case 'type':
        apiRef.current.startCellEditMode({ id: params.id, field: params.field });
        break;
      default:
      // pass
    }
  }, [apiRef])
  const handelSelectionChanged = useCallback((model: GridSelectionModel) => {
    setSelectedIds(model as number[])
  }, [])
  useGridApiEventHandler(apiRef, 'cellClick', handleCellClicked)
  useGridApiEventHandler(apiRef, 'selectionChange', handelSelectionChanged)
  const handleDelete = useCallback(() => {
    if (selectedIds.length === 0) return
    // 選択解除を行うが、先にremoveが動作すると、カラム無しでエラーが発生する
    // 発生頻度を抑えるため、setTimeout で 10ms のディレイを設定
    // useGridParamsApi.js:60 Uncaught Error: No row with id #11 found
    apiRef.current.selectRows(selectedIds, false)
    setTimeout(() => {
      handleSegmentRemoved(selectedIds)
    }, 10)
  }, [handleSegmentRemoved, selectedIds, apiRef])

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Tooltip title="追加">
        <IconButton
          size="small"
          color="primary"
          onClick={handleSegmentAdded}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="削除">
        <Box component="span">
          <IconButton
            size="small"
            color="primary"
            onClick={handleDelete}
            disabled={Boolean(selectedIds.length === 0)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  )
}

const renderType: React.FC<GridRenderCellParams<string | undefined>> = ({ value }) => (
  <> {value !== 'NULL' ? value : '未使用'}</>
)

const renderEditTypeCell: GridColDef['renderCell'] = (params) => {
  return <TypeEditComponent {...params} />;
};

const TypeEditComponent: React.FC<GridRenderCellParams<string>> = ({ id, field, value }) => {
  const apiRef = useGridApiContext()
  const handleChanged = useCallback((evt: SelectChangeEvent) => {
    const newValue = evt.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field })
  }, [id, field, apiRef])
  return (
    <Select
      value={value}
      onChange={handleChanged}
    >
      <MenuItem value="CB01">CB01</MenuItem>
      <MenuItem value="CH01">CH01</MenuItem>
      <MenuItem value="CH02">CH02</MenuItem>
      <MenuItem value="OB01">OB01</MenuItem>
      <MenuItem value="OH01">OH01</MenuItem>
      <MenuItem value="ZB01">ZB01</MenuItem>
      <MenuItem value="NULL">未使用</MenuItem>
    </Select>
  )
}

// https://mui.com/x/react-data-grid/editing/#validation
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));
const SegmentEditInputCell: React.FC<GridRenderCellParams<string>> = (props: GridRenderEditCellParams) => {
  const { error } = props;
  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  )
}
const renderEditSegment: GridColDef['renderCell'] = (params: GridRenderEditCellParams) => {
  return (<SegmentEditInputCell {...params} />)
}

const renderStatus: React.FC<GridRenderCellParams<string | undefined>> = ({ value }) => {
  if (value === undefined) return (<></>)
  if (value === 'Disabled') {
    return (
      <Tooltip title="無効">
        <Button size="small" color="error" variant="text" disableRipple>
          Disabled
        </Button>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title="有効">
        <Button size="small" color="primary" variant="text" disableRipple>
          Enabled
        </Button>
      </Tooltip>
    )
  }
}

const renderEditStatusCell: GridColDef['renderCell'] = (params) => {
  return <StatusEditComponent {...params} />;
};

const StatusEditComponent: React.FC<GridRenderCellParams<string>> = ({ id, field, value }) => {
  const apiRef = useGridApiContext();
  const handleChanged = useCallback((evt: SelectChangeEvent) => {
    const newValue = evt.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field })
  }, [id, field, apiRef])
  return (
    <Select
      value={value}
      onChange={handleChanged}
    >
      <MenuItem value="Enabled">Enabled</MenuItem>
      <MenuItem value="Disabled">Disabled</MenuItem>
    </Select>
  )
}
